var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"c-site-header",class:_vm.menuIsOpen
			? {
					'c-site-header--menu-open': true,
			  }
			: {
					'c-site-header--slim': _vm.isSlim,
					'c-site-header--invert': _vm.isInverted,
			  },style:(_vm.menuIsOpen
			? {}
			: {
					'--scroll-y-percentage': _vm.scrollYPercentage,
					'--reveal-translation': ((_vm.revealPercentage - 100) + "%"),
					'--reveal-shadow-opacity':
						(((_vm.revealPercentage / 100) * _vm.scrollYPercentage) /
							100) *
						_vm.isSlim *
						0.08 *
						(_vm.isInverted ? 0.5 : 1),
			  }),on:{"!focusin":function($event){return _vm.onFocusIn.apply(null, arguments)}}},[_c('TransitionExt',{attrs:{"name":"site-header__content","duration":"200"}},[_c('nav',{staticClass:"\n\t\t\t\tmax-w-layout-max\n\t\t\t\th-full\n\t\t\t\tmx-auto\n\t\t\t\tflex\n\t\t\t\titems-center\n\t\t\t\tpx-layout-margin\n\t\t\t"},[(_vm.$store.state.site && _vm.$store.state.site.url)?_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"400"}},[(!_vm.menuIsOpen)?_c('NuxtLinkExt',{staticClass:"inline-flex gap-x-xs/h pointer-events-auto",attrs:{"to":_vm.$store.state.site.url,"aria-label":"VIVE - Det nationale forsknings- og analysecenter for velfærd"}},[_c('SvgViveLogo',{staticClass:"w-site-header-logo"}),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"200"}},[(_vm.lang === 'en' && _vm.scrollYPercentage === 0)?_c('SvgViveSubLogoEn',{staticClass:"w-site-header-sub-logo-en <768:hidden"}):(_vm.scrollYPercentage === 0)?_c('SvgViveSubLogo',{staticClass:"w-site-header-sub-logo <768:hidden"}):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"\n\t\t\t\t\tml-auto\n\t\t\t\t\tflex\n\t\t\t\t\tjustify-end\n\t\t\t\t\titems-center\n\t\t\t\t\tgap-5xs/h\n\t\t\t\t\t>=768:gap-4xs/h\n\t\t\t\t"},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"200"}},[(_vm.scrollYPercentage === 0 && !_vm.menuIsOpen)?_c('PortalTarget',{staticClass:"\n\t\t\t\t\t\t\tc-site-header__text-container\n\t\t\t\t\t\t\thidden\n\t\t\t\t\t\t\t>=1200:inline-block\n\t\t\t\t\t\t\tmax-w-siteHeaderText\n\t\t\t\t\t\t\tpointer-events-auto\n\t\t\t\t\t\t\tpl-16\n\t\t\t\t\t\t\tml-auto\n\t\t\t\t\t\t",class:{
							'c-site-header__text-container--uk':
								_vm.lang === 'en',
						},attrs:{"tag":"li","name":"SiteHeaderText"}}):_vm._e()],1),_vm._v(" "),(_vm.isLoggedIn)?_c('li',{staticClass:"\n\t\t\t\t\t\trelative\n\t\t\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\t\t\th-48\n\t\t\t\t\t\t>=768:h-52\n\t\t\t\t\t"},[_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\th-full\n\t\t\t\t\t\t\tpx-xs/v\n\t\t\t\t\t\t\tborder\n\t\t\t\t\t\t\tbg-transparent\n\t\t\t\t\t\t\thover:bg-opacity-10\n\t\t\t\t\t\t\tpointer-events-auto\n\t\t\t\t\t\t",class:{
							'text-white border-white hover:bg-white':
								!_vm.isInverted || _vm.menuIsOpen,
							'text-theme border-theme hover:bg-theme':
								_vm.isInverted && !_vm.menuIsOpen,
						},attrs:{"tabindex":_vm.menuIsOpen ? '1' : null,"aria-disabled":_vm.isLoggingOut ? 'true' : null},on:{"click":_vm.logOut}},[_vm._v("\n\t\t\t\t\t\tLog ud\n\t\t\t\t\t")])],1):_vm._e(),_vm._v(" "),(_vm.culture && _vm.isLanguagePickerVisible)?_c('li',{staticClass:"\n\t\t\t\t\t\trelative\n\t\t\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\t\t\th-48\n\t\t\t\t\t\t>=768:h-52\n\t\t\t\t\t"},[_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\th-full\n\t\t\t\t\t\t\tpx-xs/v\n\t\t\t\t\t\t\tborder\n\t\t\t\t\t\t\tbg-transparent\n\t\t\t\t\t\t\thover:bg-opacity-10\n\t\t\t\t\t\t\tpointer-events-auto\n\t\t\t\t\t\t",class:{
							'text-white border-white hover:bg-white':
								!_vm.isInverted || _vm.menuIsOpen,
							'text-theme border-theme hover:bg-theme':
								_vm.isInverted && !_vm.menuIsOpen,
						},attrs:{"to":_vm.culture.url}},[_c('span',{staticClass:"<768:hidden"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.culture.name)+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:">=768:hidden"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.culture.shortName)+"\n\t\t\t\t\t\t")])])],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"\n\t\t\t\t\t\trelative\n\t\t\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\t\t\tw-48\n\t\t\t\t\t\th-48\n\t\t\t\t\t\t>=768:w-52 >=768:h-52\n\t\t\t\t\t"},[(_vm.searchPageUrl)?_c('BaseButton',{staticClass:"w-full h-full p-0 pointer-events-auto",class:{
							'bg-white hover:bg-theme-10 text-theme':
								!_vm.isInverted && !_vm.menuIsOpen,
							'bg-white hover:bg-red-10 text-red': _vm.menuIsOpen,
						},attrs:{"id":"search-toggle","aria-label":"Gå til søgning","to":_vm.searchPageUrl,"tabindex":_vm.menuIsOpen ? '1' : null}},[_c('SvgIconSearch',{staticClass:"w-18 h-18"})],1):_vm._e()],1),_vm._v(" "),_c('li',{staticClass:"\n\t\t\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\t\t\tw-44\n\t\t\t\t\t\th-44\n\t\t\t\t\t\t>=768:w-48 >=768:h-48\n\t\t\t\t\t"},[_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\th-full\n\t\t\t\t\t\t\tp-0\n\t\t\t\t\t\t\trounded-none\n\t\t\t\t\t\t\tpointer-events-auto\n\t\t\t\t\t\t",class:{
							'bg-white hover:bg-theme-10 text-theme':
								!_vm.isInverted && !_vm.menuIsOpen,
							'bg-white hover:bg-red-10 text-red': _vm.menuIsOpen,
						},attrs:{"id":"menu-toggle","aria-label":_vm.menuIsOpen ? 'Luk menu' : 'Åbn menu',"aria-expanded":_vm.menuIsOpen ? 'true' : 'false',"aria-controls":"c-burger-menu","tabindex":_vm.menuIsOpen ? '1' : null},on:{"click":function($event){_vm.menuIsOpen = !_vm.menuIsOpen}}},[_c('SvgIconBurger',{staticClass:"c-site-header__close-icon w-18 h-14"})],1)],1)],1)],1)]),_vm._v(" "),_c('BurgerMenu',{attrs:{"active":_vm.menuIsOpen,"aria-labelledby":"menu-toggle"},on:{"close":function($event){_vm.menuIsOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }