<template>
	<header
		class="c-site-header"
		:class="
			menuIsOpen
				? {
						'c-site-header--menu-open': true,
				  }
				: {
						'c-site-header--slim': isSlim,
						'c-site-header--invert': isInverted,
				  }
		"
		:style="
			menuIsOpen
				? {}
				: {
						'--scroll-y-percentage': scrollYPercentage,
						'--reveal-translation': `${revealPercentage - 100}%`,
						'--reveal-shadow-opacity':
							(((revealPercentage / 100) * scrollYPercentage) /
								100) *
							isSlim *
							0.08 *
							(isInverted ? 0.5 : 1),
				  }
		"
		@focusin.capture="onFocusIn"
	>
		<!-- General navigation -->
		<TransitionExt name="site-header__content" duration="200">
			<nav
				class="
					max-w-layout-max
					h-full
					mx-auto
					flex
					items-center
					px-layout-margin
				"
			>
				<!-- Vive logo -->
				<TransitionExt
					v-if="$store.state.site && $store.state.site.url"
					name="fade-in-out"
					duration="400"
				>
					<NuxtLinkExt
						v-if="!menuIsOpen"
						:to="$store.state.site.url"
						aria-label="VIVE - Det nationale forsknings- og analysecenter for velfærd"
						class="inline-flex gap-x-xs/h pointer-events-auto"
					>
						<SvgViveLogo class="w-site-header-logo" />

						<TransitionExt name="fade-in-out" duration="200">
							<SvgViveSubLogoEn
								v-if="lang === 'en' && scrollYPercentage === 0"
								class="w-site-header-sub-logo-en <768:hidden"
							/>
							<SvgViveSubLogo
								v-else-if="scrollYPercentage === 0"
								class="w-site-header-sub-logo <768:hidden"
							/>
						</TransitionExt>
					</NuxtLinkExt>
				</TransitionExt>

				<!-- Search and burger menu -->
				<ul
					class="
						ml-auto
						flex
						justify-end
						items-center
						gap-5xs/h
						>=768:gap-4xs/h
					"
				>
					<!-- Frontpage tagline -->
					<TransitionExt name="fade-in-out" duration="200">
						<PortalTarget
							v-if="scrollYPercentage === 0 && !menuIsOpen"
							tag="li"
							name="SiteHeaderText"
							class="
								c-site-header__text-container
								hidden
								>=1200:inline-block
								max-w-siteHeaderText
								pointer-events-auto
								pl-16
								ml-auto
							"
							:class="{
								'c-site-header__text-container--uk':
									lang === 'en',
							}"
						/>
					</TransitionExt>

					<!-- Log out -->
					<li
						v-if="isLoggedIn"
						class="
							relative
							flex-shrink-0 flex-grow-0
							h-48
							>=768:h-52
						"
					>
						<BaseButton
							class="
								w-full
								h-full
								px-xs/v
								border
								bg-transparent
								hover:bg-opacity-10
								pointer-events-auto
							"
							:class="{
								'text-white border-white hover:bg-white':
									!isInverted || menuIsOpen,
								'text-theme border-theme hover:bg-theme':
									isInverted && !menuIsOpen,
							}"
							:tabindex="menuIsOpen ? '1' : null"
							:aria-disabled="isLoggingOut ? 'true' : null"
							@click="logOut"
						>
							Log ud
						</BaseButton>
					</li>

					<!-- Culture -->
					<li
						v-if="culture && isLanguagePickerVisible"
						class="
							relative
							flex-shrink-0 flex-grow-0
							h-48
							>=768:h-52
						"
					>
						<BaseButton
							:to="culture.url"
							class="
								w-full
								h-full
								px-xs/v
								border
								bg-transparent
								hover:bg-opacity-10
								pointer-events-auto
							"
							:class="{
								'text-white border-white hover:bg-white':
									!isInverted || menuIsOpen,
								'text-theme border-theme hover:bg-theme':
									isInverted && !menuIsOpen,
							}"
						>
							<span class="<768:hidden">
								{{ culture.name }}
							</span>
							<span class=">=768:hidden">
								{{ culture.shortName }}
							</span>
						</BaseButton>
					</li>

					<!-- Search -->
					<li
						class="
							relative
							flex-shrink-0 flex-grow-0
							w-48
							h-48
							>=768:w-52 >=768:h-52
						"
					>
						<BaseButton
							v-if="searchPageUrl"
							id="search-toggle"
							aria-label="Gå til søgning"
							:to="searchPageUrl"
							class="w-full h-full p-0 pointer-events-auto"
							:class="{
								'bg-white hover:bg-theme-10 text-theme':
									!isInverted && !menuIsOpen,
								'bg-white hover:bg-red-10 text-red': menuIsOpen,
							}"
							:tabindex="menuIsOpen ? '1' : null"
						>
							<SvgIconSearch class="w-18 h-18" />
						</BaseButton>
					</li>

					<!-- Menu -->
					<li
						class="
							flex-shrink-0 flex-grow-0
							w-44
							h-44
							>=768:w-48 >=768:h-48
						"
					>
						<BaseButton
							id="menu-toggle"
							:aria-label="menuIsOpen ? 'Luk menu' : 'Åbn menu'"
							:aria-expanded="menuIsOpen ? 'true' : 'false'"
							aria-controls="c-burger-menu"
							class="
								w-full
								h-full
								p-0
								rounded-none
								pointer-events-auto
							"
							:class="{
								'bg-white hover:bg-theme-10 text-theme':
									!isInverted && !menuIsOpen,
								'bg-white hover:bg-red-10 text-red': menuIsOpen,
							}"
							:tabindex="menuIsOpen ? '1' : null"
							@click="menuIsOpen = !menuIsOpen"
						>
							<SvgIconBurger
								class="c-site-header__close-icon w-18 h-14"
							/>
						</BaseButton>
					</li>
				</ul>
			</nav>
		</TransitionExt>

		<!-- Burger menu -->
		<BurgerMenu
			:active="menuIsOpen"
			aria-labelledby="menu-toggle"
			@close="menuIsOpen = false"
		/>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';

import BurgerMenu from '~/components/main/BurgerMenu';
import SvgViveLogo from '~/assets/svgs/vive-logo.svg?inline';
import SvgViveSubLogo from '~/assets/svgs/vive-sub-logo.svg?inline';
import SvgViveSubLogoEn from '~/assets/svgs/vive-sub-logo-en.svg?inline';
import SvgIconSearch from '~/assets/svgs/icon-search.svg?inline';
import SvgIconBurger from '~/assets/svgs/icon-burger.svg?inline';

export default {
	name: 'SiteHeader',

	components: {
		BurgerMenu,
		SvgViveLogo,
		SvgViveSubLogo,
		SvgViveSubLogoEn,
		SvgIconSearch,
		SvgIconBurger,
	},

	props: {
		invert: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const lang =
			{
				11050: 'en',
			}[this.$store.state?.site?.cultureId] || 'da';

		const culture = this.$store.state?.site?.navigation?.culture?.find(
			(a) => a.checked === false
		);

		return {
			lang,
			culture,

			isSlim: false,
			scrollY: 0,
			scrollYPercentage: 0,
			revealPercentage: 100,
			elementHeight: 0,
			menuIsOpen: false,
			isLoggingOut: false,

			frameRequest: null,
			resizeObserver: null,
			preventNextScrollUpdate: true,
		};
	},

	computed: {
		...mapGetters(['isLoggedIn', 'isLanguagePickerVisible']),
		isInverted() {
			return this.invert && !this.menuIsOpen;
		},
		searchPageUrl() {
			return this.$store.state.site?.searchPage?.url;
		},
	},

	watch: {
		menuIsOpen(val) {
			if (!val) {
				this.preventNextScrollUpdate = true;
			}
		},

		scrollY() {
			this.updateHeaderHeightVariable();
		},
	},

	mounted() {
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onScroll);
		this.onScroll();

		this.resizeObserver = new ResizeObserver(
			this.updateHeaderHeightVariable
		);
		this.resizeObserver.observe(this.$el);
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onScroll);

		this.resizeObserver.disconnect();
		document.body.style.removeProperty('--site-header-height');
	},

	methods: {
		onScroll() {
			window.cancelAnimationFrame(this.frameRequest);
			if (this.preventNextScrollUpdate) {
				this.preventNextScrollUpdate = false;
				this.scrollY = window.scrollY;
				this.isSlim = !!(this.scrollY > 52);
				return;
			}

			this.frameRequest = window.requestAnimationFrame(() => {
				const { scrollY } = window;
				const { height } = this.$el.getBoundingClientRect();
				const scrollDelta = ((scrollY - this.scrollY) / height) * 100;

				this.revealPercentage =
					Math.round(
						Math.min(
							Math.max(
								0,
								100 - (scrollY / height) * 100,
								this.revealPercentage - scrollDelta
							),
							100
						) * 1000
					) / 1000;
				this.scrollYPercentage =
					Math.round(
						Math.min(Math.max(0, (scrollY / height) * 100), 100) *
							1000
					) / 1000;
				if (scrollDelta >= 0) {
					if (this.revealPercentage === 0) {
						this.isSlim = true;
					}
				} else {
					if (scrollY <= 52 && this.revealPercentage === 100) {
						this.isSlim = false;
					}
				}

				this.scrollY = scrollY;
				this.$nextTick(() => {
					this.elementHeight =
						this.$el.getBoundingClientRect().height;
					this.$emit('height-update', this.elementHeight);
				});
			});
		},

		onFocusIn() {
			this.revealPercentage = 100;
			this.updateHeaderHeightVariable();
		},

		updateHeaderHeightVariable() {
			this.$nextTick(() => {
				const { top, height } =
					this.$el?.getBoundingClientRect?.() || {};
				document.body.style.setProperty(
					'--site-header-height',
					`${Math.round(height + top - 1)}px`
				);
			});
		},

		logOut() {
			this.isLoggingOut = true;
			this.$axios
				.get('/api/login/logout')
				.then(({ data }) => {
					if (!data?.loggedIn) {
						this.$store.commit('SET_IS_LOGGED_IN', false);
					}
					this.isLoggingOut = false;
					this.$nuxt.refresh();
				})
				.catch(() => {
					this.isLoggingOut = false;
				});
		},
	},
};
</script>

<style lang="postcss">
.c-site-header {
	@apply fixed left-0 w-full h-site-header text-white pointer-events-none;
	transition: height 0.3s ease-in-out, background-color 0.3s 0.2s, color 0.3s;
	z-index: 1000;

	transform: translateY(var(--reveal-translation, 0%));
	--reveal-diff: (
			var(
					--theme-verticalSpacing-site-header,
					var(--theme-verticalSpacing-site-header--sm)
				) -
				var(
					--theme-verticalSpacing-site-header-slim,
					var(--theme-verticalSpacing-site-header-slim--sm)
				)
		) / 100 * (100 - var(--scroll-y-percentage, 0));
	max-height: calc(
		var(
				--theme-verticalSpacing-site-header-slim,
				var(--theme-verticalSpacing-site-header-slim--sm)
			) + var(--reveal-diff, 0px)
	);
	box-shadow: 0px 4px 24px rgba(0, 0, 0, var(--reveal-shadow-opacity, 0));
}
.c-site-header--menu-open {
	transition: transform 0.3s ease-in-out, height 0.3s ease-in-out,
		background-color 0.1s, color 0.3s;
}
.c-site-header--slim {
	@apply h-site-header-slim bg-theme pointer-events-auto;
}
.c-site-header--invert {
	@apply text-theme;

	&.c-site-header--slim {
		@apply bg-gray-light;
	}
}

.c-site-header__text-container:empty {
	@apply hidden !important;
}

.c-site-header__close-icon {
	overflow: visible;
}
.c-site-header__close-icon path {
	transition: transform 0.3s ease-in-out, opacity 0.3s;
	transform-origin: 50% 50%;
}
.c-site-header--menu-open .c-site-header__close-icon path {
	&:first-child {
		transform: rotate(-45deg) translateY(-50%) translateY(1px);
	}
	&:nth-child(2) {
		opacity: 0;
		transform: rotate(-45deg);
	}
	&:last-child {
		transform: rotate(45deg) translateY(50%) translateY(-1px);
	}
}

.c-site-header--menu-open .c-site-header__search-bar,
.c-site-header__search-bar:empty {
	@apply pointer-events-none;
}

.c-site-header__text-container--uk {
	@apply text-body-sm font-light;
	padding-left: calc(
		var(--theme-layout-column-of-12) * 1 +
			var(--theme-layout-gutter, var(--theme-layout-gutter--sm)) * 0 +
			16px
	) !important;
}

@screen print {
	.c-site-header {
		display: none;
	}
}
</style>
